defineDs('DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/AndroidFullscreenOverlay', [
], function () {

  // Vars:
  var checkTimer = null;
  var $container = document.getElementById('leia-game-inner-wrapper');
  var $overlay = document.getElementById('leia-android-fullscreen-overlay');

  // Functions:
  var isLandscape = function () {
    return (window.innerWidth > window.innerHeight);
  };

  var isFullscreen = function () {
    var fullscreenElement = document.fullscreenElement ||  // eslint-disable-line compat/compat
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement ||
      document.fullscreen; // eslint-disable-line compat/compat

    if (fullscreenElement || document.webkitIsFullScreen) {
      return true;
    }

    return false;
  };

  var applyFullscreen = function () {
    var elem = document.body;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  var showOverlay = function () {
    // Display the swipe up animation overlay (sticky)
    $container.classList.add('visible-android-fullscreen-overlay');
  };

  var hideOverlay = function () {
    $container.classList.remove('visible-android-fullscreen-overlay');
  };

  var poolingFunction = function () {
    console.warn('AndroidFullscreenOverlay pool');

    if (isFullscreen() || !isLandscape()) {
      stopTimer();
      hideOverlay();
    } else {
      showOverlay();
    }

  };

  var stopTimer = function () {
    clearInterval(checkTimer);
  };

  var startTimer = function () {
    poolingFunction();
    if (!checkTimer) {
      checkTimer = setInterval(poolingFunction, 500);
    }
  };

  var startMonitoring = function () {
    startTimer();

    window.addEventListener('resize', startTimer);

    $overlay.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      applyFullscreen();
      hideOverlay();
      stopTimer();
    });
  };

  return {
    startMonitoring: startMonitoring
  };


});
