defineDs('Shared/Framework/Mithril/Scripts/Templates/OverlayContainer',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Context:
    var OverlayContainer = function (template) {

      // Variables:
      this.$container = m.prop();
      this.$wrapper = m.prop(document.getElementById('wrapper'));

      // Functions:
      this.clear = function (action) {
        var self = this;

        return function () {
          var $container = self.$container();
          var $gameOverlay = document.querySelector('.'.concat(template.class().split(' ').join('.')));
          var $wrapper = self.$wrapper();

          $gameOverlay?.classList.add('remove-overlay');

          $wrapper?.classList.remove('overlay-active');
          $wrapper?.classList.add('remove-overlay');

          if ($gameOverlay && !$gameOverlay.getAttribute('data-binded')) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            // Bind the event only once
            $gameOverlay.setAttribute('data-binded', 'true'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            $gameOverlay.addEventListener('animationend', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              if ($container && $container.parentNode) {
                $container.parentNode.removeChild($container);
              }

              $wrapper?.classList.remove('remove-overlay');

              if (typeof action === 'function') {
                action();
              }

              m.redraw();
            });


          }
        };
      }.bind(this);

      this.render = function (confirm, dismiss) {
        var $overlay = document.createElement('div');
        var render = template.render(this.clear(confirm, 'confirm'), this.clear(dismiss, 'dismiss'));

        m.render($overlay, render);

        this.$container($overlay);
      }.bind(this);

      this.show = function (confirm, dismiss) {
        this.render(confirm, dismiss);

        this.$wrapper()?.classList.add('overlay-active');

        document.body.appendChild(this.$container());
      }.bind(this);

    };

    // Public functions:
    return OverlayContainer;

  });
