defineDs('DanskeSpil/Domain/AvalonQuick/Scripts/GamesSlider', [
  'Common/Framework/ScriptLibraries/Scripts/TinySlider',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
], function (TinySlider, Ensighten) {
  const initialize = () => {

    document.querySelectorAll('.game-section-games').forEach((gameslist) => {
      const card = gameslist.querySelector('.card').classList;
      if (window.matchMedia('(max-width: 768px)').matches) {
        gameslist.classList.remove('container');
      }

      let cardWidth = 195; // Fallback
      let itemCountMobile = 2; // Fallback
      let itemCount400 = 3; // Fallback
      let itemCount768 = 5; // Fallback
      let gutter = 30; // Fallback

      switch (true) {
      case card.contains('card--large'):
        cardWidth = 195;
        itemCountMobile = 2;
        itemCount400 = 3;
        itemCount768 = 5;
        gutter = 50;
        break;
      case card.contains('card--medium'):
        cardWidth = 140;
        itemCountMobile = 2;
        itemCount400 = 2;
        itemCount768 = 5;
        gutter = 30;
        break;
      case card.contains('card--small'):
        cardWidth = 130;
        itemCountMobile = 3;
        itemCount400 = 4;
        itemCount768 = 6;
        gutter = 20;
        break;
      case card.contains('card--micro'):
        cardWidth = 85;
        itemCountMobile = 4;
        itemCount400 = 4;
        itemCount768 = 8;
        gutter = 20;
        break;
      }

      const slider = TinySlider({
        container: gameslist.querySelector('.game-section-games__slider'),
        controls: true,
        prevButton: '#' + gameslist.querySelector('.game-section-games__controls--prev').id,
        nextButton: '#' + gameslist.querySelector('.game-section-games__controls--next').id,
        gutter: gutter,
        nav: true,
        navPosition: 'top',
        mouseDrag: true,
        lazyload: true,
        items: itemCountMobile,
        fixedWidth: cardWidth,
        swipeAngle: 45,
        // In order to use fixedWith in responsive, loop must be true, and freezable must be false. Bug in TNS?
        loop: false,
        freezable: true,
        responsive: {
          400: {
            items: itemCount400,
          },
          768: {
            items: itemCount768,
          }
        },
        onInit: (thisSlider) => {
          thisSlider.container.parentElement.style.margin = '0'; // this is because in tinyslider css is refered by styling a dynamic id
          const sliderTitle = gameslist.querySelector('.game-section-games__text h1').innerText;
          gameslist.querySelector('.game-section-games__slider').classList.remove('game-section-games__slider-not-initialized');
          gameslist.querySelector('.game-section-games__controls--prev').onclick = () => {
            Ensighten.pushGaEvent('campaign_module', 'arrow-left', sliderTitle);
          };
          gameslist.querySelector('.game-section-games__controls--next').onclick = () => {
            Ensighten.pushGaEvent('campaign_module', 'arrow-right', sliderTitle);
          };
          addTrackingToCards(gameslist);
        }
      });
      slider.events.on('transitionStart', () => {
        addTrackingToCards(gameslist);
      });
    });
  };

  const addTrackingToCards = (gameslist) => {
    const sliderTitle = gameslist.querySelector('.game-section-games__text h1').innerText;
    gameslist.querySelectorAll('.game-section-games__slider .card').forEach((card) => {
      const cardLink = card.querySelector('.card__link');
      if (!cardLink.classList.contains('has-tracking-event')) {
        cardLink.addEventListener('click', () => {
          cardLink.classList.add('has-tracking-event');
          const action = `click_${sliderTitle.toLowerCase().replace(/ /g, '_')}`;
          const label = card.querySelector('.card__meta-2').innerText;
          // const position = findClickedActiveCard(allActiveCards, card); // we cannot as is use send this 'position' as 'value' When using pushGaEventOnPageRedirect. Maybe in the fufure
          Ensighten.pushGaEventOnPageRedirect('campaign_module', action, label);
        });
      }
    });
  };

  /* const findClickedActiveCard = (allActiveCards, card) => {
    let position = -1;
    allActiveCards.forEach((activeCard, activeIndex) => {
      if (activeCard.getAttribute('id') === card.parentElement.getAttribute('id')) {
        position = activeIndex + 1;
      }
    });
    return position;
  }; */

  initialize();

});

