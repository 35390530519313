defineDs('DanskeSpil/Domain/GameVendorLEIA/Scripts/LeiaGameLauncher', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/LeiaHostChannel',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaDataStore',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaApi',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaUtils',
  'Shared/Framework/Mithril/Scripts/Helpers/IOSUtils',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/IOSFullscreenOverlay',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/AndroidFullscreenOverlay',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'Common/Framework/EventHandling/Scripts/AppEvent',
], function (Utils, Event, LeiaHostChannel, LeiaDataStore, LeiaApi, LeiaUtils, IOSUtils, IOSFullscreenOverlay, AndroidFullscreenOverlay, LoginCache, CrossWindowEvents, AppEvent) {

  // Functions:
  var loadFalconBridge = function () {
    var promise = new Promise(function (resolve) {
      window.include(DS.Config.LEIA_HOST + '/static/bridge/falcon-game-bridge-helper.min.js', function () {
        resolve();
      });
    });

    return promise;
  };

  var showErrorMessage = function (errorMessage) {
    var domMessage = document.getElementById('leia-message');
    var domContainer = document.getElementById('leia-game-inner-wrapper');

    if (domMessage) {
      domMessage.innerText = errorMessage;
    }
    if (domContainer) {
      domContainer.classList.remove('loading');
      domContainer.classList.add('error');
    }
  };

  var bindResizeEvents = function () {
    window.addEventListener('resize', function () {
      // Resize on ios after adressbar/fullscreen toggle doesnt seem to work unless we add delay. Lets try with 300ms
      setTimeout(LeiaUtils.setGameContainerSize, 300);
    });

    Event.subscribe('header-area-height-changed', function () {
      // Delay a bit, to be sure DOM is updated before detecting/setting sizes.
      setTimeout(LeiaUtils.setGameContainerSize, 300);
    });
  };

  var isApp = function () {
    return location.href.toLowerCase().indexOf('dsapplicationid') > -1;
  };

  var iosSwipeUpHack = function () {
    console.warn('IOSUtils.isIOS()', IOSUtils.isIOS());
    console.warn('IOSUtils.isLandscape()', IOSUtils.isLandscape());
    console.warn('IOSUtils.isFullscreen()', IOSUtils.isFullscreen());

    if (Utils.isMobile() && IOSUtils.isIOS()) {
      // No footer on this page (as we want nothing below game container)
      var $footer = document.querySelector('.js-global-footer');
      if ($footer) {
        $footer.style.display = 'none';
      }

      if (!isApp()) {
        IOSFullscreenOverlay.startMonitoring();
      }
    }
  };

  var androidFullscreenHack = function () {
    var isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;

    if (Utils.isMobile() && isAndroid && !isApp()) {
      AndroidFullscreenOverlay.startMonitoring();
    }
  };

  var subcribeBalanceUpdate = function () {
    // For app: Fire event when balance is updated (and only when updated)
    var oldBalance = LoginCache.isLoggedIn() ? LoginCache.getUserObj().customerInfo.balance : null;
    CrossWindowEvents.subscribe('ds.event.balanceUpdated', function () {
      var newBalance = LoginCache.isLoggedIn() ? LoginCache.getUserObj().customerInfo.balance : null;
      if (oldBalance != newBalance) {
        AppEvent.fire('update-user-balance', null);
      }

      oldBalance = newBalance;
    });
  };

  var getGviChannel = function () {
    var isApp = location.href.toLowerCase().indexOf('dsapplicationid') > -1;
    if (isApp) {
      return 'APP';
    }

    if (Utils.isMobile() || Utils.isTabletDevice()) {
      return 'MOBILE';
    }

    return 'WEB';
  };

  var loadGame = function (gameData, options) {
    // Setup FDJ HostChannel:
    var hostChannel = new LeiaHostChannel();
    hostChannel.setName(gameData.name);
    hostChannel.setType(gameData.engineCode);

    var bridgeSetup = {
      hostChannel: hostChannel,
      bridgeLocation: DS.Config.LEIA_HOST + '/static/bridge/loader.html',
      parentNode: options.domNode,
      crossDomain: {
        hostKey: DS.Config.LEIA_HOSTKEY,
        authorizedHosts: [DS.Config.LEIA_HOST, location.origin]
      },
      betId: options.betId,
      skipRules: options.skipRules ? true : false,
      lotteryChannel: getGviChannel()
    };

    window.fcGameBridge.openGame(bridgeSetup);
  };

  var init = function () {
    var domNode = document.getElementById('leia-game');
    if (!domNode) {
      return;
    }

    var gameId = domNode.getAttribute('data-game-id');
    var checkForActiveGame = domNode.getAttribute('data-check-for-active-game') === 'true';
    var betId = Utils.getParameter('betId');
    var skipRules = Utils.getParameter('skipRules');

    if (!gameId) {
      console.warn('LEIA Game Launcher: name param should be set');
      return;
    }

    // Override login returnUrl to add skipRules if not already
    if (!skipRules) {
      document.body.setAttribute('data-override-login-return-url', location.pathname + location.search + (location.search.indexOf('?') > -1 ? '&' : '?') + 'skipRules=1');
    }

    document.body.setAttribute('data-override-logout-return-url', '/quick');

    // Precalculate the container-size:
    LeiaUtils.setGameContainerSize();

    // gameInfo feed + falcon bridge loader:
    var promises = [LeiaApi.getGameInfo(gameId), loadFalconBridge()];

    // IU-16635 + IU-17842
    if (checkForActiveGame) {
      promises.push(LeiaApi.checkActiveBetId(gameId));
    }

    if (window.dsApplicationConfig && window.dsApplicationConfig.ApplicationId === 'ISOBAR_LOTTO_MOBILENATIVE') {
      subcribeBalanceUpdate();
    }

    // Wait for promises
    Promise.all(promises).then(function (values) {
      // values[0] = game info
      // values[1] = null (load falcon bridge response)
      // values[2] = activebet (if needed)

      var response = values[0];

      if (!response || !response.data) {
        console.error('No game data');
        showErrorMessage('Spillet er lukket eller tjenesten midlertidigt nede.');
        return;
      }

      if (response.data.status !== 'open') {
        showErrorMessage('Spillet er lukket.');
        return;
      }

      LeiaDataStore.gameData = response.data;

      if (values[2]) {
        betId = values[2];
      }

      loadGame(response.data, {
        domNode: domNode,
        betId: betId,
        skipRules: skipRules
      });

      bindResizeEvents();

      iosSwipeUpHack();
      androidFullscreenHack();

    }, function () {
      // One of promises rejects
      showErrorMessage('Spillet er lukket eller tjenesten midlertidigt nede.');
    });

  };

  // Init:
  init();

});
