defineDs('DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaDataStore', function () {

  var store = {
    gameData: null,
    name: null,
    latestGameDimensions: null,
    stakeAmount: null, // used for tracking
    purchaseCompleted: null, // used for tracking
  };

  return store;

});
