defineDs('DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/IOSFullscreenOverlay', [
  'Shared/Framework/Mithril/Scripts/Helpers/IOSUtils'
], function (IOSUtils) {

  // Vars:
  var $container = document.getElementById('leia-game-inner-wrapper');
  var $spacer = document.getElementById('leia-game-ios-swipe-up-spacer');

  var overlayHasAppeared = false;

  // Functions:

  var showOverlay = function () {
    overlayHasAppeared = true;

    // Show spacer, before the game container, so we can inject the scrolling option
    if ($spacer) {
      $spacer.style.display = 'block';
    }

    // Display the swipe up animation overlay (sticky)
    $container.classList.add('visible-swipe-up-overlay');

    // Have swipeoverlay for 3s before hide
    window.addEventListener('touchend', function () {
      setTimeout(function () {
        hideOverlay();
      }, 3000);
    });
  };

  var hideOverlay = function () {
    $container.classList.remove('visible-swipe-up-overlay');

    if ($spacer) {
      $spacer.style.display = 'none';
    }
  };

  var poolingFunction = function () {
    console.warn('IOSFullscreenOverlay pool');

    // Always hide if switch to portrait
    if (!IOSUtils.isLandscape() && overlayHasAppeared) {
      hideOverlay();
      return;
    }

    if (!overlayHasAppeared) {
      showOverlay();
    }

  };

  var startMonitoring = function () {
    window.addEventListener('resize', poolingFunction);
  };

  return {
    startMonitoring: startMonitoring
  };

});
