defineDs('DanskeSpil/Domain/AvalonQuick/Scripts/GameListSpot', [
  'DanskeSpil/Domain/AvalonComponents/Scripts/Slider'
], function (Slider) {

  const state = {
    input: '',
    filters: []
  };

  let $dropdowns;
  let $filterBadges;

  const initStateFilters = () => {
    $dropdowns.forEach(($dropdown) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      let filters = {};
      $dropdown.querySelectorAll('.js-dropdown-item').forEach(($item) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const categoryId = $item.getAttribute('data-category').trim(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        filters[categoryId] = { label: $item.textContent.trim(), active: true, checked: false };
      });
      state.filters[$dropdown.querySelector('.js-dropdown-label')?.textContent.trim()] = filters; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    });

    stateUpdated();
  };

  const allItemsInDropDownUnchecked = () => {
    for (let dropDownLabel in state.filters) {
      let countChecked = 0;

      for (let categoryId in state.filters[dropDownLabel]) {
        if (state.filters[dropDownLabel].hasOwnProperty(categoryId)) {
          if (state.filters[dropDownLabel][categoryId].checked) {
            countChecked++;
          }
        }
      }

      if (countChecked === 0) {
        // no checked items in dropdown, set all active
        for (let categoryId in state.filters[dropDownLabel]) {
          if (state.filters[dropDownLabel].hasOwnProperty(categoryId)) {
            state.filters[dropDownLabel][categoryId].active = true;
          }
        }
      } else {
        // at least one checked item in dropdown, set active equal to checked state
        for (let categoryId in state.filters[dropDownLabel]) {
          if (state.filters[dropDownLabel].hasOwnProperty(categoryId)) {
            state.filters[dropDownLabel][categoryId].active = state.filters[dropDownLabel][categoryId].checked;
          }
        }
      }
    }
  };

  const typedInSearch = (ev) => {
    state.input = ev.target.value.trim().toLowerCase();
    stateUpdated();
  };

  const clickedOnFilterOrBadge = (ev) => {
    // due to js bubbling, we only want to take action on when input checkbox is clicked
    if (ev.target.tagName === 'INPUT') {
      const categoryId = ev.currentTarget.getAttribute('data-category').trim();
      for (let dropDownLabel in state.filters) {
        if (state.filters[dropDownLabel][categoryId]) {
          state.filters[dropDownLabel][categoryId].checked = !state.filters[dropDownLabel][categoryId].checked;
        }
      }
      stateUpdated();
    }
  };

  const stateUpdated = () => {
    allItemsInDropDownUnchecked();
    updateFilters();
    updateBadges();
    updateCards();
    updateCardContainers();
    Slider.handler();
  };

  const updateBadges = () => {
    $filterBadges.forEach(($badge) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const categoryId = $badge.getAttribute('data-category').trim(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const checkBox = $badge.querySelector('input'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      for (let dropDownLabel in state.filters) {
        if (state.filters[dropDownLabel][categoryId]) {
          const checked = state.filters[dropDownLabel][categoryId].checked;
          checkBox.checked = checked;
          checkBox.parentElement.style.display = checked ? 'inline-block' : 'none';
        }
      }
    });
  };

  const updateFilters = () => {
    $dropdowns.forEach(($dropdown) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const dropDownLabel = $dropdown.querySelector('.js-dropdown-label').textContent.trim(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      let countChecked = 0;
      $dropdown.querySelectorAll('.js-dropdown-item').forEach(($item) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const categoryId = $item.getAttribute('data-category').trim(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const $checkBox = $item.querySelector('input'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        $checkBox.checked = state.filters[dropDownLabel][categoryId].checked;
        if ($checkBox.checked) {
          countChecked++;
        }
      });

      $dropdown.classList.toggle('active-filter', countChecked > 0);
    });
  };

  const updateCards = () => {
    const $cards = document.querySelectorAll('.js-card');
    $cards.forEach(($card) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const cardCategoryIds = $card.getAttribute('data-categories'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

      const activeFilter = (dropDownLabel) => {
        for (let filterCategoryId in state.filters[dropDownLabel]) {
          if (state.filters[dropDownLabel].hasOwnProperty(filterCategoryId)) {
            const active = state.filters[dropDownLabel][filterCategoryId].active;
            const filterInCardCategories = cardCategoryIds.indexOf(filterCategoryId) > -1;
            if (active && filterInCardCategories) {
              return true;
            }
          }
        }
        return false;
      };

      const titleIsValid = () => {
        const cardTitle = $card.querySelector('.js-card-title').textContent.trim().toLowerCase(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        return cardTitle.indexOf(state.input) > -1;
      };

      const show = ((activeFilter('Pris') && activeFilter('Gevinst') && titleIsValid()) || document.querySelector('.FeaturedGameListSpot') !== null);
      $card.classList.toggle('hidden', !show);
      $card.classList.toggle('swiper-slide', show);
    });
  };

  const updateCardContainers = () => {
    const $cardContainers = document.querySelectorAll('.js-card-container');
    $cardContainers.forEach(($cardContainer) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const visibleCardsInContainer = $cardContainer.querySelectorAll('.js-card:not(.hidden)').length; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $cardContainer.style.display = visibleCardsInContainer === 0 ? 'none' : '';
    });

    const visibleCardsOnPage = document.querySelectorAll('.js-card:not(.hidden)').length;
    document.querySelector('.js-no-games').style.display = visibleCardsOnPage > 0 ? 'none' : 'block';
  };

  const toggleDropDown = (ev) => {
    ev.currentTarget?.classList.toggle('open');
  };

  const handleClickOutsideDropDown = () => {
    document.addEventListener('mouseup', (ev) => {
      if (!ev.target.classList.contains('js-dropdown') // if the target of the click isn't the dropdown...
        && !ev.target.closest('.js-dropdown')) { // ... nor a descendant of the dropdown

        $dropdowns.forEach(($dropdown) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
          $dropdown.classList.remove('open');
        });
      }
    });
  };

  const initialize = () => {
    if (document.body.classList.contains('.mode-edit')) {
      return;
    }
    if (!document.querySelector('.game-list-spot-component')) {
      return;
    }

    $dropdowns = document.querySelectorAll('.js-dropdown');
    $filterBadges = document.querySelectorAll('.js-filterbadge');

    $dropdowns.forEach(($dropdown) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $dropdown.addEventListener('click', toggleDropDown); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    });

    const $dropdownItems = document.querySelectorAll('.js-dropdown-item');
    $dropdownItems.forEach(($item) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $item.addEventListener('click', clickedOnFilterOrBadge); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    });

    $filterBadges.forEach(($badge) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $badge.addEventListener('click', clickedOnFilterOrBadge); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    });

    document.querySelector('.js-search')?.addEventListener('input', typedInSearch);

    document.querySelector('form')?.addEventListener('submit', (ev) => {
      ev.preventDefault();
    });

    handleClickOutsideDropDown();
    initStateFilters();
  };

  initialize();

});

