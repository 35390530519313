defineDs('DanskeSpil/Domain/AvalonQuick/Scripts/GameList', [
  'DanskeSpil/Domain/AvalonComponents/Scripts/Slider'
], function (Slider) {

  /**
   * Initialize Slider logic on GameList cards
   */
  function init() {

    // We are not on mobile, never initialize slider logic
    if (!document.querySelector('.is-mobile-detected')) {
      return;
    }

    var containers = document.querySelectorAll('.js-game-list-spot');

    containers.forEach(function (elem) {
      // Get the parent mega-mode element, because the HTML markup required for the Swiper library to work starts with the mega-mode parent
      var $parent = elem.closest('.swiper-container');
      var numberOfCards = elem.querySelectorAll('.card').length;


      var sliderConfig = {
        swiper: null,
        shouldInitialize: function () {

          var isPortrait = window.matchMedia('(orientation: portrait)').matches;
          var isLandscape = window.matchMedia('(orientation: landscape)').matches;
          var isAboveBreakpoint = window.matchMedia('(min-width: 768px)').matches;

          // Do not initialize if we are above screen width breakpoint, ie. bigger than mobile
          if (isAboveBreakpoint) {
            return false;
          }

          // We are on mobile, check if we have room for the cards to show, and only initialize if we do not
          return (numberOfCards > 1 && isPortrait) || (numberOfCards > 3 && isLandscape);
        },
        target: $parent,
        settings: {
          slidesPerView: 'auto',
          freeMode: true,
          freeModeMomentumRatio: 0.8,
          freeModeMomentumVelocityRatio: 0.5,
          freeModeMomentumBounceRatio: 0.5,
          touchEventsTarget: 'wrapper',
          scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            hide: false,
            dragSize: '30',
          },
        }
      };

      // Add swiper configuration to the global swiper handler
      Slider.addSlider(sliderConfig);

    });
  }

  document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('.mode-edit')) return;
    init();
  });
});
