defineDs('DanskeSpil/Domain/AvalonQuick/Scripts/FeaturedGames', [
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'DanskeSpil/Domain/AvalonComponents/Scripts/Slider',
  'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils',
], function (Storage, Slider, DOMUtils) {

  var storageKey = 'quick-featured-games';
  var storageMaxGames = 2;
  var previousFeaturedGames = '';

  // let's get rid of invalid games (it's valid only if game already exist on the page)
  function filterOutInvalidGames(games) {
    return games.filter(function (gameLink) {
      var $game = document.querySelectorAll('.js-card[href="' + gameLink + '"]');
      return $game.length > 0;
    });
  }

  // get an array of any stored game links
  function getFeaturedGamesFromStorage() {
    var games = Storage.get(storageKey);
    games = games ? JSON.parse(games) : [];
    // Make sure we only prepare a number that match the current max (ie. number of selected featured games in Sitecore)
    games = games.slice(0, storageMaxGames);
    return games;
  }

  // save array of game links to storage
  function saveGamesToStorage(games) {
    // we only need to save the X number of featured games
    if (games.length > storageMaxGames) {
      games = games.slice(0, storageMaxGames);
    }
    Storage.set(storageKey, JSON.stringify(games));
  }

  // user clicked a game, let's save the link of the game to storage
  function updateFeaturedGames(ev) {
    const clickedGameLink = ev.target.closest('a').attributes['href'].nodeValue;

    var games = getFeaturedGamesFromStorage();

    // stop if the game is already in the list.
    if (document.querySelector('.js-featured-cards .card[href="' + clickedGameLink + '"]')) return;

    // if game is already in array, remove it, we don't want to see two of the same games
    if (games.indexOf(clickedGameLink) > -1) {
      games.splice(games.indexOf(clickedGameLink), 1);
    }

    // add the clicked game link as first in the list
    games.unshift(clickedGameLink);

    // let's save our updated links
    saveGamesToStorage(games);
  }

  // add games to DOM
  function showFeaturedGames() {
    var games = getFeaturedGamesFromStorage();

    // don't bother messing with the DOM if the featured games are the same as we already show
    if (games && games.toString() !== previousFeaturedGames) {
      previousFeaturedGames = games.toString();

      // Make room for the saved games, by removing the same number of cards from the DOM as we allow
      var count = 0;
      while (count < games.length) {
        [...document.querySelectorAll('.js-featured-cards .card')].at(-1).remove();
        count++;
      }

      games.reverse().map(function (gameLink) {
        // find a matching game, copy its DOM elements and children, put it in Featured Games
        var $game = document.querySelector('.js-card[href="' + gameLink + '"]');
        if ($game) {
          var $clonedGame = $game.cloneNode(true); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          var megacardOverlay = $game.dataset['megacardOverlay'];
          var megacardDesktop = $game.dataset['megacardDesktop'];
          var megacardTablet = $game.dataset['megacardTablet'];
          var megacardMobile = $game.dataset['megacardMobile'];
          var description = $game.dataset['description'];

          // add game trigger
          $clonedGame.addEventListener('click', updateFeaturedGames); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

          // add game to DOM
          document.querySelector('.js-featured-cards').prepend($clonedGame);

          // edit DOM with new photos
          const featuredCard = document.querySelector('.js-featured-cards .js-card[href="' + gameLink + '"]');
          featuredCard.querySelector('.card-image__overlay').src = megacardOverlay;
          featuredCard.querySelector('.card-image__background-desktop').srcset = megacardDesktop;
          featuredCard.querySelector('.card-image__background-tablet').srcset = megacardTablet;
          featuredCard.querySelector('.card-image__background-mobile').srcset = megacardMobile;
          featuredCard.querySelector('.card__title').insertAdjacentHTML('afterend', '<p class="card__description">' + description + '</p>');
        }

      });

      // add layout classes
      var $cards = document.querySelectorAll('.js-featured-cards .js-card');
      if  ($cards.length) {
        [...$cards].at(-1).classList.add('last-card');
      }

      Slider.handler();
    }
  }

  // any cards that were initially loaded as featured games should be added to storage
  function addAnyInitialGamesToStorage() {
    const $initialCards = document.querySelectorAll('.js-featured-cards .js-card');

    if ($initialCards.length) {
      var games = getFeaturedGamesFromStorage();

      // if an initial game is not already in storage, append it to storage
      $initialCards.forEach(($card) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        var link = $card.attributes['href'].nodeValue;
        if (games.indexOf(link) === -1) {
          games.push(link);
        }
      });

      // make sure we still have the game running
      // (in case user had a long break, once playing a game that now no longer is available)
      games = filterOutInvalidGames(games);

      // save our games to storage
      saveGamesToStorage(games);
    }
  }

  // Set number of games we allow to be saved. The number matches the selected items on pageload, minus one
  function setStorageMaxGames() {
    storageMaxGames = document.querySelectorAll('.js-featured-cards .card').length - 1;
  }

  function init() {
    // detect if we have GameListSpot cards on the page
    var $cards = document.querySelectorAll('.game-list-spot-component .js-card');

    if ($cards.length > 0) {
      // listen for clicks on games in their container, intercept
      DOMUtils.addEventListeners($cards, 'click', updateFeaturedGames);

      // init
      setStorageMaxGames();
      addAnyInitialGamesToStorage();
      showFeaturedGames();
    }

  }


  /**
   * Handle when and when not to initialize cards as a slider
   */
  function initializeSliderLogic() {

    // We are not on mobile, never initialize slider logic
    if (!document.querySelector('.is-mobile-detected')) {
      return;
    }

    var containers = document.querySelectorAll('.js-featured-cards');

    containers.forEach(function (elem) {

      // Get the parent mega-mode element, because the HTML markup required for the Swiper library to work starts with the mega-mode parent
      var $parent = elem.closest('.mega-mode');
      var numberOfCards = elem.querySelectorAll('.card').length;

      var sliderConfig = {
        swiper: null,
        shouldInitialize: function () {

          var isPortrait = window.matchMedia('(orientation: portrait)').matches;
          var isLandscape = window.matchMedia('(orientation: landscape)').matches;
          var isAboveBreakpoint = window.matchMedia('(min-width: 768px)').matches;

          // Do not initialize if we are above screen width breakpoint, ie. bigger than mobile
          if (isAboveBreakpoint) {
            return false;
          }

          // We are on mobile, check if we have room for the cards to show, and only initialize if we do not
          return (numberOfCards > 1 && isPortrait) || (numberOfCards > 2 && isLandscape);
        },
        target: $parent,
        // swiper config object
        settings: {
          slidesPerView: 'auto',
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          }
        }
      };

      // Add swiper configuration to the global swiper handler
      Slider.addSlider(sliderConfig);

    });
  }

  DOMUtils.ready(function () {
    if (document.querySelector('.mode-edit')) return;
    init();
    initializeSliderLogic();
  });
});

