defineDs('DanskeSpil/Domain/AvalonQuick/Scripts/HeroDeckSliderSpot', [
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'Common/Framework/ScriptLibraries/Scripts/TinySlider',
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (Ensighten, TinySlider, m) {
  if (!document.querySelector('.hero-deck__hero')) {
    return;
  }
  // Initiate Hero fields that should be updated
  const heroBackgroundImage = document.querySelector('.hero-deck__background');
  const heroForegroundImage = document.querySelector('.hero-deck__hero-image');
  const price = document.querySelector('.js-hero-deck-link span');
  // const metatextContainer = document.querySelector('hero-deck__hero-texts');
  const metatext1 = document.querySelector('.hero-deck__hero-prize-text');
  const metatext2 = document.querySelector('.hero-deck__hero-title');
  const metatext3 = document.querySelector('.hero-deck__hero-type-text');
  const metatext1Mobile = document.querySelector('.hero-deck__hero-texts--mobile .hero-deck__hero-prize-text');
  const metatext2Mobile = document.querySelector('.hero-deck__hero-texts--mobile .hero-deck__hero-title');
  const metatext3Mobile = document.querySelector('.hero-deck__hero-texts--mobile .hero-deck__hero-type-text');
  const link = document.querySelector('.js-hero-deck-link');
  const container = document.querySelector('.hero-deck__slider-container');
  let slider = null;

  const cardClicked = (e) => {
    e.preventDefault();
    const startingElement = e.target.closest('.card');
    const cardPrice = startingElement.querySelector('.card__label--price span').innerText;
    const meta1 = startingElement.querySelector('.card__meta .card__meta-1').innerText;
    const meta2 = startingElement.querySelector('.card__meta .card__meta-2').innerText;
    const meta3 = startingElement.querySelector('.card__meta .card__meta-3').innerText;
    const cardLink = startingElement.querySelector('a.card__link')?.getAttribute('data-href');

    link.classList.add('quick-button--animate');
    window.setTimeout(() => {
      link.classList.remove('quick-button--animate');
    }, 4000);

    link.setAttribute('href', cardLink);

    if (window.matchMedia('(max-width: 767px)').matches && !startingElement.classList.contains('active')) { // only slide to next card if the user clicks, and its not on the current card
      if (e.pageX < (document.body.clientWidth / 2)) {
        slider.goTo('prev');
      } else {
        slider.goTo('next');
      }
    }
    document.querySelectorAll('.hero-deck__slider-container .card').forEach(($card) => {
      $card.classList.remove('active');
    });
    startingElement.classList.add('active');

    // Append all values from clicked card to Hero
    price.innerText = cardPrice;
    animateMetaTexts();
    metatext1.innerText = meta1;
    metatext2.innerText = meta2;
    metatext3.innerText = meta3;
    metatext1Mobile.innerText = meta1;
    metatext2Mobile.innerText = meta2;
    metatext3Mobile.innerText = meta3;

    const additionalImageData = startingElement.querySelector('.card__link').getAttribute('data-additional');
    if (additionalImageData) {
      const additionalDataElement = JSON.parse(additionalImageData);
      if (additionalDataElement) {
        document.querySelectorAll('.hero-deck__hero-additional-images>.additional-images').forEach((element) => element.classList.remove('hidden'));
        renderAdditionalImages(additionalDataElement);
        heroForegroundImage.setAttribute('src', additionalDataElement.promoForegroundImage ?? '');
        heroBackgroundImage.setAttribute('style', `background-image: url(${additionalDataElement.promoBackgroundImage ?? ''});`);
      } else {
        document.querySelectorAll('.hero-deck__hero-additional-images>.additional-images').forEach((element) => element.classList.add('hidden'));
      }
    }
  };

  const handleCta = ((ev) => {
    ev.preventDefault();
    const cta = ev.currentTarget;
    const href = cta.getAttribute('href');
    const label = metatext2?.innerText || href || cta.innerText;

    Ensighten.pushGaEventOnPageRedirect('campaign_module', 'top_spot_click', label);

    setTimeout(() => {
      location.href = href;
    }, 100);
  });

  const animateMetaTexts = () => {
    metatext1.classList.add('animating');
    metatext2.classList.add('animating');
    metatext3.classList.add('animating');
    metatext1Mobile.classList.add('animating');
    metatext2Mobile.classList.add('animating');
    metatext3Mobile.classList.add('animating');

    window.setTimeout(() => {
      metatext1.classList.remove('animating');
      metatext2.classList.remove('animating');
      metatext3.classList.remove('animating');
      metatext1Mobile.classList.remove('animating');
      metatext2Mobile.classList.remove('animating');
      metatext3Mobile.classList.remove('animating');
    }, 500);
  };

  const addEventListenersToCards = () => {
    const cards = document.querySelectorAll('.hero-deck__slider-container .card');
    cards.forEach((item, index) => {
      if (item.classList.contains('has-events')) {
        return;
      }

      item.querySelector('.card__link').addEventListener('click', cardClicked);
      item.addEventListener('mouseover', () => {
        focus(item, index, cards);
      });
      item.classList.add('has-events');
    });
  };

  container.addEventListener('mouseleave', () => {
    resetCards(container.querySelectorAll('.card'));
  });

  const resetCards = (cards) => {
    cards.forEach((card) => {
      card.classList.remove('card__hover');
      card.classList.remove('card__previous');
      card.classList.remove('card__next');
      card.classList.remove('card__before-previous');
      card.classList.remove('card__after-next');
    });
  };

  const focus = (elem, index, cards) => {
    let previous = index - 1;
    let beforePrevious = index - 2 === -1 ? false : index - 2;
    let next = index + 1;
    let afterNext = index + 2 === cards.length ? false : index + 2;
    resetCards(cards);
    elem.classList.add('card__hover');
    cards[previous]?.classList.add('card__previous');
    cards[next]?.classList.add('card__next');
    cards[beforePrevious]?.classList.add('card__before-previous');
    cards[afterNext]?.classList.add('card__after-next');
  };

  const renderAdditionalImages = ({ additionalImages }) => {
    if (additionalImages) {
      const additionalImageHtml = [
        additionalImages.map((additionalImage) => {
          return m('div', { class: `additional-images additional-images--position-${additionalImage.position} ${additionalImage.quickStylingClass?.classname ?? ''}` },
            additionalImage?.svgContent ?
              m.trust(additionalImage.svgContent) :
              m('img', { src: additionalImage.imageUrl }));
        })
      ];
      m.render(document.querySelector('.hero-deck__hero-additional-images'), additionalImageHtml);
    }
  };

  const initialize = () => {
    link?.addEventListener('click', handleCta);

    const heroDeckSlider = document.querySelector('.hero-deck__slider');
    heroDeckSlider.querySelectorAll('.hero-deck__slider-container .card__link').forEach(($card) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $card.addEventListener('click', cardClicked); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    });

    slider = TinySlider({
      container: document.querySelector('.hero-deck__slider'),
      controls: true,
      loop: true,
      items: 3,
      mouseDrag: true,
      lazyload: true,
      prevButton: '.hero-deck__controls--prev',
      nextButton: '.hero-deck__controls--next',
      nav: false,
      center: true,
      responsive: {
        768: {
          loop: false,
          center: false,
          items: 6,
        },
        1024: {
          items: 8,
        },
        1440: {
          items: 9,
        }
      },
      onInit: () => {
        document.querySelector('.hero-deck__slider-container--slider-not-initialized').classList.remove('hero-deck__slider-container--slider-not-initialized');
        setTimeout(() => { // gives a very little delay before event class is added to cards, so that lazyloading works better, as this is used in a slider
          addEventListenersToCards();
        }, 100);
      },
    });
    slider.events.on('transitionStart', () => {

      heroDeckSlider.querySelectorAll('.card').forEach((card) => {
        card.classList.remove('active');
      });
      addEventListenersToCards();
    });
    slider.events.on('transitionEnd', () => {
      // mobile sizes only
      if (window.matchMedia('(max-width: 767px)').matches) {
        heroDeckSlider.querySelectorAll('.card.tns-slide-active').forEach((card, index) => {
          card.classList.remove('active');
          if (index === 1) {
            card.classList.add('active');
          }
        });
      }
    });
    slider.events.on('touchMove', () => {
      heroDeckSlider.querySelectorAll('.card').forEach((card) => {
        card.classList.remove('active');
      });
    });
  };

  initialize();

});

