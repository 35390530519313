defineDs('DanskeSpil/Domain/AvalonQuick/Scripts/SearchGames', [
  'Shared/Framework/Ensighten/Scripts/Ensighten'], function (Ensighten) {

  if (!document.querySelector('.search-games-component') && !document.querySelector('.search-spot')) {
    return;
  }

  const state = {
    input: '',
    filters: [],
    hasTags: [],
    hasFilters: []
  };

  let $dropdowns;
  let $filterToggle;
  let $filterBadges;
  let $tags;
  let $clearSearch;
  let $searchInput;
  let $resultHeadingStandard;
  let $resultHeadingSearchPhrase;
  let $resultHeadingFilters;
  let $searchSpotToggle;
  let $searchSpotInput;

  const initStateFilters = () => {
    $dropdowns.forEach(($dropdown) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      let filters = {};
      $dropdown.querySelectorAll('.js-dropdown-item').forEach(($item) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const categoryId = $item.getAttribute('data-category').trim(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        filters[categoryId] = { label: $item.textContent.trim(), active: true, checked: false };
      });
      state.filters[$dropdown.querySelector('.js-dropdown-label')?.textContent.trim()] = filters; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

    });
    let tags = {};
    document.querySelectorAll('.js-searchtag').forEach((searchTag) => {
      tags[searchTag.dataset.tag] = { label: searchTag.textContent.trim(), checked: false };
    });
    state.filters['Tags'] = tags;
    stateUpdated();
  };

  const allItemsInDropDownUnchecked = () => {
    for (let dropDownLabel in state.filters) {
      let countChecked = 0;

      for (let categoryId in state.filters[dropDownLabel]) {
        if (state.filters[dropDownLabel].hasOwnProperty(categoryId)) {
          if (state.filters[dropDownLabel][categoryId].checked) {
            countChecked++;
          }
        }
      }

      if (countChecked === 0) {
        // no checked items in dropdown, set all active
        for (let categoryId in state.filters[dropDownLabel]) {
          if (state.filters[dropDownLabel].hasOwnProperty(categoryId)) {
            state.filters[dropDownLabel][categoryId].active = true;
          }
        }
      } else {
        // at least one checked item in dropdown, set active equal to checked state
        for (let categoryId in state.filters[dropDownLabel]) {
          if (state.filters[dropDownLabel].hasOwnProperty(categoryId)) {
            state.filters[dropDownLabel][categoryId].active = state.filters[dropDownLabel][categoryId].checked;
          }
        }
      }
    }
  };

  const typedInSearch = (ev) => {
    state.input = ev.target.value.trim().toLowerCase();
    stateUpdated();
    initResultHeader();
    document.querySelector('.js-search-phrase').innerHTML = `"${state.input}"`;
    window.history.pushState(state.input, 'Search', `/quick/soeg?q=${state.input}`);
    if (state.input === '') {
      $resultHeadingStandard.classList.add('active');
      return;
    }
    $resultHeadingSearchPhrase.classList.add('active');
  };

  const clickedOnFilterOrBadge = (ev) => {
    // due to js bubbling, we only want to take action on when input checkbox is clicked
    ev.target.closest('.js-dropdown').classList.remove('open');
    if (ev.target.tagName === 'INPUT') {
      const categoryId = ev.currentTarget.getAttribute('data-category').trim();
      for (let dropDownLabel in state.filters) {
        if (state.filters[dropDownLabel][categoryId]) {
          state.filters[dropDownLabel][categoryId].checked = !state.filters[dropDownLabel][categoryId].checked;
        }
      }
      stateUpdated();
    }
    ev.target.closest('.js-dropdown').click();
  };

  const stateUpdated = () => {
    allItemsInDropDownUnchecked();
    updateFilters();
    updateBadges();
    updateCards();
    updateCardContainers();
  };

  const updateBadges = () => {
    $filterBadges.forEach(($badge) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const categoryId = $badge.getAttribute('data-category').trim(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const checkBox = $badge.querySelector('input'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      for (let dropDownLabel in state.filters) {
        if (state.filters[dropDownLabel][categoryId]) {
          const checked = state.filters[dropDownLabel][categoryId].checked;
          checkBox.checked = checked;
          checkBox.parentElement.style.display = checked ? 'inline-block' : 'none';
        }
      }
    });
  };

  const updateFilters = () => {
    $dropdowns.forEach(($dropdown) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const dropDownLabel = $dropdown.querySelector('.js-dropdown-label').textContent.trim(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      let countChecked = 0;
      $dropdown.querySelectorAll('.js-dropdown-item').forEach(($item) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const categoryId = $item.getAttribute('data-category').trim(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const $checkBox = $item.querySelector('input'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        $checkBox.checked = state.filters[dropDownLabel][categoryId].checked;
        if ($checkBox.checked) {
          countChecked++;
        }
      });

      $dropdown.classList.toggle('active-filter', countChecked > 0);
      // Show the right header text
      if ($dropdown.classList.contains('active-filter')) {
        state.hasFilters.push('a');
        initResultHeader();
        state.hasFilters.length > 0 || state.hasTags.length > 0 ? $resultHeadingFilters.classList.add('active') : '';
      } else {
        state.hasFilters.length === 0 && state.hasTags.length === 0 ? initResultHeader(true) : '';
        state.hasFilters.splice('a');
      }
    });
  };

  const updateCards = () => {
    const $cards = document.querySelectorAll('.js-card');
    $cards.forEach(($card) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const cardCategoryIds = $card.getAttribute('data-categories'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

      const activeFilter = (dropDownLabel) => {
        for (let filterCategoryId in state.filters[dropDownLabel]) {
          if (state.filters[dropDownLabel].hasOwnProperty(filterCategoryId)) {
            const active = state.filters[dropDownLabel][filterCategoryId].active;
            const filterInCardCategories = cardCategoryIds.indexOf(filterCategoryId) > -1;
            if (active && filterInCardCategories) {
              return true;
            }
          }
        }
        return false;
      };

      const titleIsValid = () => {
        const cardTitle = $card.querySelector('.js-card-title').textContent.trim().toLowerCase(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        return cardTitle.indexOf(state.input) > -1;
      };

      const tagIsCheckedOnCard = () => {
        // check if any tags is checked in searchbar
        let anyTagsChecked = false;
        for (let tag in state.filters.Tags) {
          if (state.filters.Tags[tag].checked) {
            anyTagsChecked = true;
          }
        }
        if (!anyTagsChecked) {
          return true;
        }
        const tagsOnCard = JSON.parse($card.getAttribute('data-tags').trim()); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        const cardHasTag = tagsOnCard.some((tag) => {
          return state.filters.Tags[tag].checked;
        });
        return cardHasTag;
      };
      tagIsCheckedOnCard();

      const show = (activeFilter('Pris') && activeFilter('Gevinst') && titleIsValid() && tagIsCheckedOnCard());
      $card.classList.toggle('hidden', !show);
    });
  };

  const updateCardContainers = () => {
    const $cardContainers = document.querySelectorAll('.js-card-container');
    $cardContainers.forEach(($cardContainer) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      const visibleCardsInContainer = $cardContainer.querySelectorAll('.js-card:not(.hidden)').length; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $cardContainer.style.display = visibleCardsInContainer === 0 ? 'none' : '';
    });

    if (document.querySelector('.js-no-games')) {
      const visibleCardsOnPage = document.querySelectorAll('.js-card:not(.hidden)').length;
      document.querySelector('.js-no-games').style.display = visibleCardsOnPage > 0 ? 'none' : 'block';
    }
  };

  const clearSearch = (ev) => {
    ev.preventDefault();
    $tags.forEach(($tag) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $tag.classList.remove('selected');
    });
    initStateFilters();
    $searchInput.value = '';
    state.input = '';
    stateUpdated();
    initResultHeader(true);
  };

  const toggleDropDown = (ev) => {
    ev.currentTarget?.classList.toggle('open');
    if (ev.target.nodeName === 'SPAN') {
      const labelHtml = `${ev.target.parentElement.parentElement.parentElement.parentElement.querySelector('.js-dropdown-label').innerHTML} > ${ev.target.innerHTML}`;
      Ensighten.pushGaEvent('filter', 'dropdown', labelHtml);
    }
  };

  const toggleFilter = () => {
    $filterToggle.classList.toggle('open');
    document.querySelector('.js-filters').classList.toggle('open');
  };

  const tagClicked = (ev) => {
    const tagId = ev.target.getAttribute('data-tag');
    ev.target.classList.toggle('selected');

    if (state.filters['Tags'][tagId].checked) {
      state.filters['Tags'][tagId].checked = false;
    } else {
      Ensighten.pushGaEvent('filter', 'select', state.filters['Tags'][tagId].label);
      state.filters['Tags'][tagId].checked = true;
    }
    // Show the right header text
    tagSelected();
    stateUpdated();
  };

  const tagSelected = () => {
    for (let i = 0; i < $tags.length; i++) {
      if ($tags[i].classList.contains('selected')) {
        state.hasTags.push(i);
        initResultHeader();
        state.hasTags.length > 0 || state.hasFilters.length > 0 ? $resultHeadingFilters.classList.add('active') : '';
      } else {
        state.hasTags.length === 0 && state.hasFilters.length === 0 ? initResultHeader(true) : '';
        state.hasTags.splice(i);
      }
    }
  };

  const handleClickOutsideDropDown = () => {
    document.addEventListener('mouseup', (ev) => {
      if (!ev.target.classList.contains('js-dropdown') // if the target of the click isn't the dropdown...
        && !ev.target.closest('.js-dropdown')) { // ... nor a descendant of the dropdown

        $dropdowns.forEach(($dropdown) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
          $dropdown.classList.remove('open');
        });
      }
    });
  };

  const initResultHeader = (reset) => {
    const activeClass = 'active';
    if ($resultHeadingStandard) {
      $resultHeadingStandard.classList.remove(activeClass);
      $resultHeadingSearchPhrase.classList.remove(activeClass);
      $resultHeadingFilters.classList.remove(activeClass);
      if (reset) {
        $resultHeadingStandard.classList.add(activeClass);
      }
    }
  };

  const goToSearchPage = () => {
    const searchUrl = $searchSpotInput.getAttribute('data-search-spot-url'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    const searchPhrase = $searchSpotInput.value;
    window.location.href = `${searchUrl}?q=${searchPhrase.toLowerCase()}`;
  };

  const goToSearchPageOnEnter = (e) => {
    e.keyCode === 13 ? goToSearchPage() : null;
  };

  const getSearchPhraseFromUrl = () => {
    let params = new URLSearchParams(document.location.search);
    let searchPhraseFromUrl = params.get('q');
    let mainSearchInput = document.querySelector('.js-search');
    if (searchPhraseFromUrl && mainSearchInput) {
      window.setTimeout(() => {
        mainSearchInput.value = searchPhraseFromUrl;
        state.input = searchPhraseFromUrl;
        stateUpdated();
        document.querySelector('.js-search-phrase').innerHTML = `"${state.input}"`;
        initResultHeader();
        $resultHeadingSearchPhrase.classList.add('active');

      }, 100);
    }
  };

  const toggleSearchSpot = () => {
    document.querySelector('.search-spot__input-container').classList.toggle('visible');
    $searchSpotToggle.classList.toggle('hidden');
    $searchSpotInput.focus(); // eslint-disable-line no-jquery/no-other-methods, no-jquery/no-event-shorthand -- Reason: Not a jquery element
  };

  const initialize = () => {
    if (document.body.classList.contains('.mode-edit')) {
      return;
    }

    if (!document.querySelector('.search-games-component') && !document.querySelector('.search-spot')) {
      return;
    }

    $dropdowns = document.querySelectorAll('.js-dropdown');
    $filterBadges = document.querySelectorAll('.js-filterbadge');
    $filterToggle = document.querySelector('.js-filtertoggle');
    $tags = document.querySelectorAll('.js-searchtag');
    $clearSearch = document.querySelector('.js-clear-search');
    $searchInput = document.querySelector('.js-search');
    $resultHeadingStandard = document.querySelector('.js-search-result-heading-standard');
    $resultHeadingSearchPhrase = document.querySelector('.js-search-result-search-phrase');
    $resultHeadingFilters = document.querySelector('.js-search-result-filters');
    $searchSpotToggle = document.querySelector('.js-search-spot-toggle');
    $searchSpotInput = document.querySelector('.js-search-spot-input');

    if ($filterToggle) {
      $filterToggle.addEventListener('click', toggleFilter); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    }
    $tags.forEach(($tag) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $tag.addEventListener('click', tagClicked); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    });

    $dropdowns.forEach(($dropdown) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $dropdown.addEventListener('click', toggleDropDown); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    });

    const $dropdownItems = document.querySelectorAll('.js-dropdown-item');
    if ($dropdownItems.length) {
      $dropdownItems.forEach(($item) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        $item.addEventListener('click', clickedOnFilterOrBadge); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      });
    }

    $filterBadges.forEach(($badge) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $badge.addEventListener('click', clickedOnFilterOrBadge); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    });

    document.querySelector('.js-search')?.addEventListener('input', typedInSearch);

    if ($clearSearch) {
      $clearSearch.addEventListener('click', clearSearch); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    }
    document.querySelector('.js-search-spot-button')?.addEventListener('click', goToSearchPage);
    if ($searchSpotInput) {
      $searchSpotInput.addEventListener('keydown', goToSearchPageOnEnter); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      $searchSpotToggle.addEventListener('click', toggleSearchSpot);// eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
    }
    document.querySelector('form')?.addEventListener('submit', (ev) => {
      ev.preventDefault();
    });

    handleClickOutsideDropDown();
    initStateFilters();
    initResultHeader(true);
    getSearchPhraseFromUrl();

  };

  initialize();

});

