defineDs('DanskeSpil/Domain/GameVendorLEIA/Scripts/LeiaHostChannel', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaDataStore',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaApi',
  'DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaUtils'
], function (Utils, Ensighten, LoginCache, SitecoreAuth, LeiaDataStore, LeiaApi, LeiaUtils) {

  // Game is a subclass of HostChannel:
  var Game = (function () {

    var executeAction = function (action, parameters) {
      console.debug('GAME - executeAction', action, parameters);

      if (action === 'closeGame') {
        closeGame();
      }

      if (action === 'globalRules') {
        LeiaUtils.gotoGlobalRules();
      }

      if (action == 'gameRules') {
        LeiaUtils.gotoGameRules();
      }

      if (action == 'saveTag') {
        saveTag(parameters);
      }

      if (action == 'gameLoading') {
        // Remove loading class from container
        LeiaUtils.removeLoadingClass();
      }

      // isGameEnabled and openGame actions are for Cross Sale functionality
      if (action === 'isGameEnabled') {
        return isGameEnabled(parameters);
      }

      if (action === 'openGame') {
        return openGame(parameters);
      }

    };

    var executeEvent = function (eventType, eventData) {
      console.debug('GAME - executeEvent', eventType, eventData);

      var gameEvents = [
        'setDimension',
        'saveTag',
        'preBet',
        'onBet',
        'onClaim'
      ];

      if (gameEvents.indexOf(eventType) > -1 && Object.prototype.hasOwnProperty.call(Game, eventType)) {
        // If so, trigger the method
        return Game[eventType](eventData);
      } else {
        // The action triggered doesn't exist in this class. So we have to trigger an error
        console.debug('ReferenceError: lottery.game.bridge.host.error - The event ' + eventType + ' triggered by ITF bridge is not defined in the application. EventData: ', eventData);
      }
    };

    var setDimension = function (data) {
      console.debug('GAME - setDimension', data);
      LeiaUtils.setGameContainerSize(data);
    };

    var onBet = function (data) {
      console.debug('GAME - onBet', data);
      LeiaUtils.setTopNavigationDisplayOnMobileLandScape(false);
      LeiaUtils.setGameContainerSize();
      LeiaUtils.appEventEmitter('on-bet', { betAmount: data.betAmount?.value });
    };

    var onClaim = function (data) {
      console.debug('GAME - onClaim', data);
      LeiaUtils.setTopNavigationDisplayOnMobileLandScape(true);
      LeiaUtils.setGameContainerSize();
    };

    var isGameEnabled = function (data) { // Used by FDJ games for cross sale functionality
      var promise = new Promise(function (resolve, reject) {
        if (!data.gameId) {
          reject();
        }

        LeiaApi.getGameInfo(data.name).then(function (response) {
          if (response && response.data && response.data.status === 'open') {
            resolve(true);
          } else {
            resolve(false);
          }
        });

      });

      return promise;
    };

    var openGame = function (data) { // Used by FDJ games for cross sale functionality

      var promise = new Promise(function (resolve, reject) {
        if (!data.gameId) {
          reject();
        }

        LeiaApi.getGameInfo(data.name).then(function (response) {
          if (response && response.data && response.data.status === 'open' && response.data.cmsData && response.data.cmsData.gameUrl) {
            location.href = response.data.cmsData.gameUrl;
          } else {
            resolve(false);
          }
        });

      });

      return promise;
    };

    var saveTag = function (eventData) {
      console.debug('GAME - saveTag', eventData);

      if (eventData.name === 'reveal' && eventData.eventType === 'view') {
        // Remove loading class from container
        LeiaUtils.removeLoadingClass();
      }



      // WORKAROUND - WHEN LANDSCAPE REMOVE HEADER WHEN GAME IS IN ACTION:
      var headerChangeAction = null;

      // For standard LEIA games:
      if (eventData.event === 'betRequest' && eventData.eventType === 'bet' && eventData.viewInfo
        && (eventData.viewInfo.state === 'didAppear' || eventData.viewInfo.state === 'willAppear')
        && (eventData.viewInfo.name === 'reveal' || eventData.viewInfo.name === 'home' || eventData.viewInfo.name === 'game')) { // Quick Games: 'reveal'/'game', other game:'home'

        headerChangeAction = 'HIDE';
      }

      // For Motors Game (e.g Kortkrig):
      if (eventData.event === 'createMtrBet' && eventData.eventType === 'requestParams') {
        headerChangeAction = 'HIDE';
      }

      // Both standard + Motors Games
      if (eventData.name === 'result' && eventData.eventType === 'view') {
        headerChangeAction = 'SHOW';
      }


      // If headerChangeAction, perform header change:
      if (headerChangeAction === 'HIDE' || headerChangeAction === 'SHOW') {
        console.debug('headerChangeAction', headerChangeAction);

        if (headerChangeAction === 'HIDE') {
          // Hide navigation on landscape
          LeiaUtils.setTopNavigationDisplayOnMobileLandScape(false);
        } else {
          // Show navigation on landscape
          LeiaUtils.setTopNavigationDisplayOnMobileLandScape(true);
        }

        LeiaUtils.setGameContainerSize();
        // This timeout because setGameContainerSize is calculating the header height, and this is not always updated at this point. To be re-visited later.
        setTimeout(LeiaUtils.setGameContainerSize, 200);
      }


      // TRACKING COMES HERE:

      var gameName = LeiaDataStore.gameData.name;
      if (eventData.event === 'loaded' && eventData.eventType === 'game' && eventData.gameInfo) {
        // First tracking event: view product
        var addProduct = LeiaUtils.trackingProductDetails(gameName, null);
        LeiaUtils.addToDatalayer({
          event: 'productDetail',
          ecommerce: {
            detail: {
              products: [addProduct]
            }
          }
        });
      }

      if ((eventData.eventType.event === 'createITGBet' || ['createITGBet', 'createEinBet', 'createMtrBet'].includes(eventData.event)) && LeiaDataStore.purchaseCompleted !== true) {
        // Third tracking event: purchase completed
        var stakeAmount = LeiaDataStore.stakeAmount;
        var purchaseProduct = LeiaUtils.trackingProductDetails(gameName, stakeAmount);
        var transactionId = 'LEIA_' + Math.ceil(Math.random() * 10e10);

        if (stakeAmount) {
          LeiaUtils.addToDatalayer({
            event: 'purchaseComplete',
            ecommerce: {
              purchase: {
                actionField: {
                  id: transactionId, // Transaction ID – please use a random unique GUID
                  revenue: stakeAmount // please use the total amount	played for when the ‘køb’ is ‘gennemført’
                },
                products: [purchaseProduct]
              }
            }
          });
          LeiaDataStore.purchaseCompleted = true;
        }

      }

      if (eventData.event === 'buttonClick' && ['instant', 'auto'].includes(eventData.name)) {
        Ensighten.pushGaEvent('Quick_spil', eventData.name, eventData.gameName);
      }

    };

    var preBet = function (eventData) {
      console.debug('GAME - preBet', eventData);

      if (eventData && eventData.demo === true) {
        // In demo-mode, just return resolved promise;
        var demoPromise = new Promise(function (resolve) {
          resolve();
        });
        return demoPromise;
      }

      var stakeAmount = eventData.stake.value / 100;
      LeiaDataStore.stakeAmount = stakeAmount;

      // Second tracking event: add to card
      var gameName = LeiaDataStore.gameData.name;
      var product = LeiaUtils.trackingProductDetails(gameName, stakeAmount);
      LeiaUtils.addToDatalayer({
        event: 'addToCart',
        ecommerce: {
          add: {
            products: [product]
          }
        }
      });
      LeiaDataStore.purchaseCompleted = false;

      // Check balance and display deposit prompt if needed:

      var promise = new Promise(function (resolve, reject) {

        // Check balance
        var user = LoginCache.getUserObj();
        if (user.customerInfo && stakeAmount > 0) {
          var balance = user.customerInfo.balance;
          if (stakeAmount > balance) {
            // Balance not ok, so display predeposit overlay
            LeiaUtils.showPreDepositOverlay().then(function () {
              LeiaUtils.gotoDeposit();
            }, function () {
              reject();
            });

            return;
          }

          // Balance ok
          resolve();
        }
      });

      return promise;
    };

    var start = function (type, code) {
      console.debug('GAME - Start the game', type, code);
    };

    var closeGame = function () {
      console.debug('GAME - closeGame');
      LeiaUtils.gotoExitGame();
    };

    var gameRules = function () {
      console.debug('GAME - gameRules');
    };

    var globalRules = function () {
      console.debug('GAME - globalRules');
    };

    var credit = function () {
      console.debug('GAME - credit');

      LeiaUtils.gotoDeposit();
    };

    return {
      executeAction,
      executeEvent,
      setDimension,
      preBet,
      saveTag,
      start,
      closeGame,
      gameRules,
      globalRules,
      credit,
      onBet,
      onClaim
    };
  })();

  var HostChannel = function () {
    this.type = null;
    this.name = null;

    this.gameMode = 'default'; // variable to store the gameMode for updateGameMode and getGameMode

    this.setName = function (name) {
      this.name = name;
      LeiaDataStore.name = name;
    };

    this.setType = function (type) {
      this.type = type;
    };


    /**
     * The “emitEvent()” method is used by the game bridge to tell the host implementation that an event occurred.
     * @param string eventType
     * @param json eventData
     * @return void
     */
    this.emitEvent = function (eventType, eventData) {
      console.debug('HostChannel : emitEvent', eventType);
      var switchEvent = {
        DIMENSIONS: 'setDimension',
        TAG: 'saveTag',
        PREBET: 'preBet',
        HAS_BET: 'onBet',
        HAS_CLAIMED: 'onClaim'
      };

      return Game.executeEvent(switchEvent[eventType], eventData);
    };

    /**
     * The “getHostInformation()” method is used by the bridge to retrieve host information from the host implementation.
     * @return Promise
     */
    this.getHostInformation = function () {
      var promise = new Promise(function (resolve) {
        var response = {
        };
        console.debug('HostChannel : getHostInformation resolve', response);
        resolve(response);
      });

      return promise;
    };

    /**
     * The “updateGameMode()” method is used by the bridge to tell the host implementation that the game mode should be switched.
     * This is usually requested by the game itself, when it allows switching from normal mode to a demo mode, for example.
     * @param string gameMode
     * @return string
     */
    this.updateGameMode = function (gameMode) {
      console.debug('HostChannel : updateGameMode', gameMode);
      this.gameMode = gameMode;

      var promise = new Promise(function (resolve) {
        console.debug('HostChannel : updateGameMode resolve', gameMode);
        resolve(gameMode);
      });

      return promise;

    }.bind(this);

    /**
     * The “getGameMode()” method is used by the bridge to retrieve the game mode chosen by the host implementation.
     * @return Promise
     */
    this.getGameMode = function () {
      var gameMode = this.gameMode;
      console.debug('HostChannel : getGameMode');

      var promise = new Promise(function (resolve) {
        console.debug('HostChannel : getGameMode resolve', gameMode);
        resolve(gameMode);
      });

      return promise;
    }.bind(this);

    /**
     * The “getGameConfiguration()” method is used by the bridge to retrieve game configuration parameters from the host implementation.
     * @param string type
     * @param string name
     * @return Promise
     */
    this.getGameConfiguration = function (type, name) {
      console.debug('HostChannel : getGameConfiguration', type, name);

      var promise = new Promise(function (resolve, reject) {

        if (!LeiaDataStore.gameData) {
          console.error('No game data in store');
          reject();
          return;
        }

        var response = {
          theme: {
            id: LeiaDataStore.gameData.themeId
          },
          visualIdentity: {
            id: 'default',
            url: ''
          },
          logo: {
            url: ''
          },
          parameters: {
          }
        };

        console.debug('HostChannel : getGameConfiguration resolve', response);
        resolve(response);
      });

      return promise;
    };

    /**
    * The "getGameInformation()" method is used by the bridge to retrieve game information from the host implementation.
    * @param string type
    * @param string name
    * @return Promise
    */
    this.getGameInformation = function (type, name, t) {
      console.debug('HostChannel : getGameInformation', type, name, t);

      var promise = new Promise(function (resolve) {
        var response = {
          type: type,
          name: name,
          label: ''
        };
        console.debug('HostChannel : getGameInformation resolve', response);
        resolve(response);
      });

      return promise;
    };

    /**
     * The "getLocaleInformation()" is used by the bridge to retrieve internationalization information from the host implementation.
     * Returned data are extracted from the catalog API.
     * @return Promise
     */
    this.getLocaleInformation = function () {
      console.debug('HostChannel : getLocaleInformation');

      var promise = new Promise(function (resolve) {
        var response = {
          availableLanguages: ['en-US', 'da-DK'],
          activeLanguage: 'da-DK',
          activeCurrency: 'DKK'
        };
        console.debug('HostChannel : getLocaleInformation resolve', response);
        resolve(response);
      });

      return promise;
    };

    /**
     * The “getUserInformation()” method is used by the bridge to request information about the user from the host implementation.
     * Return data are extracted from sessionStorage set on login
     * @param boolean forceAuthenticated
     * @param string sessionTokenType
     * @return Promise
     */
    this.getUserInformation = function (forceAuthenticated, sessionTokenType, options) {
      console.debug('HostChannel : getUserInformation', forceAuthenticated, sessionTokenType, options);

      var promise = new Promise(function (resolve) {

        var response = {};
        response.gender = 'M';
        response.firstName = '';
        response.lastName = '';

        if (!forceAuthenticated) {
          var user = LoginCache.getUserObj();
          if (!user || user.customerInfo) {
            // User logged not logged in
            console.debug('HostChannel : getUserInformation resolve', response);
            resolve({});
          } else {
            // User logged in
            console.debug('HostChannel : getUserInformation resolve', response);
            resolve(response);
          }

          return;
        }

        // Login session (and fresh assertionId) is required:
        LeiaUtils.relaySSO(options).then(function (sessionToken) {
          // OK - User logged in, and we got the assertionId
          response.sessionToken = sessionToken;
          console.debug('HostChannel : getUserInformation resolve', response);
          resolve(response);

        }, function () {

          // Not logged in. Fire up login (Will enforce page navigation, and stop the promise chain)
          LeiaUtils.openLogin();

        });

      });

      return promise;
    };

    /**
     * The “getPurseInformation()” method is used by the bridge to retrieve purse information from the host implementation.
     * @return Promise
     */
    this.getPurseInformation = function () {
      console.debug('HostChannel : getPurseInformation');

      var promise = new Promise(function (resolve, reject) {

        DSAPI.Account.doBalanceUpdate();

        SitecoreAuth.getUserObj(function (data) {
          if (data && data.customerInfo && typeof data.customerInfo.balance !== 'undefined') {

            var balance = Utils.numberFormat(data.customerInfo.balance, 2, '.', '');

            var response = {
              values: {},
              displayedAmount: {
                value: balance, // Format: 100.00
                currency: 'DKK'
              }
            };

            console.debug('HostChannel : getPurseInformation resolve', response);
            resolve(response);
          } else {
            console.warn('NO VALID USERSESSION OBJECT');
            console.debug('HostChannel : getPurseInformation reject');
            reject({});
          }
        });

      });

      return promise;
    };

    /**
     * The “executionAction()” method is used by the bridge to delegate an action to the host implementation.
     * @param integer actionId
     * @param json parameters
     * @return void
     */
    this.executeAction = function (actionId, parameters) {
      console.debug('HostChannel : executeAction ->', actionId);
      var switchAction = {
        CLOSE_GAME: 'closeGame',
        CREDIT: 'credit',
        GAME_RULES: 'gameRules',
        GLOBAL_RULES: 'globalRules',
        OPEN_GAME: 'openGame',
        TAG: 'saveTag',
        GAME_LOADING: 'gameLoading',
        IS_GAME_ENABLED: 'isGameEnabled'
      };

      return Game.executeAction(switchAction[actionId], parameters);
    };

    this.maskPurse = function (purseMask) {
      console.debug('HostChannel : maskPurse', purseMask);

      var promise = new Promise(function (resolve) {

        var user = LoginCache.getUserObj();
        var balance = user && user.customerInfo ? Utils.numberFormat(user.customerInfo.balance, 2, '.', '') : null;
        var response = {
          displayedAmount: {
            value: balance,
            currency: 'DKK'
          },
          maskId: 1
        };

        console.debug('HostChannel : maskPurse resolve', response);
        resolve(response);

      });

      return promise;
    };

    this.clearPurse = function (maskId) {
      console.debug('HostChannel : clearPurse', maskId);

      var promise = new Promise(function (resolve) {
        var user = LoginCache.getUserObj();
        var balance = user && user.customerInfo ? Utils.numberFormat(user.customerInfo.balance, 2, '.', '') : null;
        var response = {
          displayedAmount: {
            value: balance,
            currency: 'DKK'
          },
          maskId: 1
        };

        console.debug('HostChannel : clearPurse resolve', response);

        resolve(response);
      });

      return promise;
    };
  };

  // Public functions:
  return HostChannel;

});
