defineDs('DanskeSpil/Domain/GameVendorLEIA/Scripts/Helpers/LeiaApi', [
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
], function (ApiRequest) {

  var baseURL = '/dlo/scapi/danskespil/gamevendorleia';

  var getGameInfo = function (args) {
    return ApiRequest({
      url: baseURL + '/gameinfo/' + args,
      localCacheTTL: 60,
      unwrapSuccess: function (response) {
        return response;
      }
    });
  };

  var checkActiveBetId = function (gameId) {
    var promise = new Promise(function (resolve) {

      ApiRequest({
        url: DS.Config.CONTEXTPREFIX + `/scapi/danskespil/playeraccountmanagement/playerGame/${gameId}/activeExternalId`,
        requireAuthCookie: true,
        logging: false, // For avoid Sentry error if user has logged in.
        timeout: 5, // Delay game loading max 5 sec
      }).then(function (response) {
        var activeBetId = null;

        if (response && response.data) {
          activeBetId = response.data;
        }

        resolve(activeBetId);

      }, function () {
        // PAM request went wrong - just resolve with non active bet.
        resolve(null);
      });

    });

    return promise;

  };

  // Public functions:
  return {
    getGameInfo: getGameInfo,
    checkActiveBetId: checkActiveBetId
  };

});
