defineDs('DanskeSpil/Domain/AvalonQuick/Scripts/WinnerStories', [
  'Common/Framework/ScriptLibraries/Scripts/TinySlider',
  'Shared/Framework/Ensighten/Scripts/Ensighten'
], function (TinySlider, Ensighten) {
  const initialize = () => {
    document.querySelectorAll('.winner-stories').forEach((list) => {
      TinySlider({
        container: list.querySelector('.winner-stories__slider'),
        controls: true,
        prevButton: '.winner-stories__controls--prev',
        nextButton: '.winner-stories__controls--next',
        gutter: 20,
        nav: true,
        navPosition: 'bottom',
        mouseDrag: true,
        loop: false,
        items: 1,
        freezable: true,
        responsive: {
          768: {
            items: 2,
          },
          1024: {
            gutter: 60,
            items: 3
          }
        },
        onInit: () => {
          const sliderTitle = list.querySelector('.winner-stories__title').innerHTML;

          list.querySelector('.winner-stories__controls--prev').onclick = function () {
            Ensighten.pushGaEvent('campaign_module', 'arrow-left', sliderTitle);
          };
          list.querySelector('.winner-stories__controls--next').onclick = function () {
            Ensighten.pushGaEvent('campaign_module', 'arrow-right', sliderTitle);
          };
        }
      });
    });
  };

  initialize();
});

