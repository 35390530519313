defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/HeroBannerFeeds', function () {

  // Dont continue, if sitecore editor
  if (window.sitecoreEnv) {
    return;
  }

  // DOM elements
  var $elements = document.querySelectorAll('.avalon-hero__heading, .avalon-hero__text, .avalon-joker__prize');

  // Dont continue, if no elements for replacing.
  if ($elements.length === 0) {
    return;
  }

  // Only load JackpotInfo if needed (as it is not in all bundles)
  requireDs('DanskeSpil/Framework/PuljeFeed/Scripts/JackpotInfo', function (JackpotInfo) {

    JackpotInfo.ready.then(function () {
      // Helper function to format Joker jackpot
      var formatJoker = function (feed) {
        var feedText = feed;
        if (!feedText || feedText === '0') {
          feedText = '<span>mindst</span> 2'; // at least 2 mio in the jackpot
        }
        return feedText;
      };

      // Prepare data for replacement:
      var jackpotData = {
        vikinglotto: JackpotInfo.getByGame('vikinglotto').poolSizeFormatted || 'min. 2',
        lotto: JackpotInfo.getByGame('lotto').poolSizeFormatted || 'min. 2',
        eurojackpot: JackpotInfo.getByGame('eurojackpot').poolSizeFormatted,
        jokerloerdag: formatJoker(JackpotInfo.getByGame('jokerloerdag').poolSizeFormatted),
        jokeronsdag: formatJoker(JackpotInfo.getByGame('jokeronsdag').poolSizeFormatted)
      };

      // Perform replacements:
      for (var i = 0; i < $elements.length; i++) {
        var element = $elements[i];
        var elementText = element.innerHTML.toString();

        Object.keys(jackpotData).forEach(function (key) {
          elementText = elementText.replace('{' + key + '}', jackpotData[key]);
        });

        element.innerHTML = elementText;
      }

    });

  });

});
