defineDs('DanskeSpil/Domain/GameVendorLEIA/Scripts/Templates/PredepositOverlay', ['Shared/Framework/Mithril/Scripts/Core/Mithril'], function (m) {

  // Template:
  var PredepositOverlay = function () {

    // Return:
    return {
      class: m.prop('leia-predeposit-overlay'),

      render: function (confirm, dismiss) {
        return m('div', { class: this.class() }, [
          m('div', { class: 'pannel' }, [
            m('div', { class: 'close-overlay-button-wrapper', onclick: dismiss },
              m('img', { class: 'button-icon', src: '/Components/DanskeSpil/Domain/GameVendorLEIA/Graphics/Close.svg' })
            ),
            m('h2', { class: 'headline' }, 'Indbetal til spilkonto'),
            m('p', { class: 'text' }, 'Du har desværre ikke penge nok på din konto til at foretage et køb. Sæt flere penge ind og vind.'),
            m('div', { class: 'buttons' }, [
              m('button', { class: 'btn button-confirm', onclick: confirm }, 'Indbetal her')
            ])
          ])
        ]);
      }
    };

  };

  // Public functions:
  return PredepositOverlay;

});
