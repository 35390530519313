defineDs('DanskeSpil/Domain/AvalonComponents/Scripts/Slider',
  ['DanskeSpil/Domain/AvalonComponents/Scripts/Libraries/Swiper',
    'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils'],
  function (Swiper, DOMUtils) {

    var swipers = [];
    var swiperActiveClass = 'swiper-active';

    /**
     * Determine if we should add slider functionality to each or the configurations added
     */
    var handler = function () {
      swipers.forEach(function (swiper) {

        if ((swiper.shouldInitialize && swiper.shouldInitialize())) {

          // Show the scrollbar container element when we have a slider
          const swiperElements = swiper.target.querySelectorAll('.swiper-scrollbar, .swiper-pagination');
          swiperElements.forEach(($element) => {
            $element.style.display = '';
          });

          if (swiper.swiper) {
            if (Array.isArray(swiper.swiper)) {
              swiper.swiper.forEach(function (sw) {
                sw.refresh();
              });
            } else {
              swiper.swiper.refresh();
            }
          } else {
            swiper.swiper = new Swiper(swiper.target, swiper.settings);
          }

          swiper.target.classList.add(swiperActiveClass);

        } else {
          if (swiper.swiper) {
            if (Array.isArray(swiper.swiper)) {
              swiper.swiper.forEach(function (sw) {
                sw.destroy(true, true);
              });
            } else {
              swiper.swiper.destroy(true, true);
            }
            swiper.swiper = undefined;

            swiper.target.classList.remove(swiperActiveClass);
          }

          // Hide the scrollbar container element when we have no slider
          const swiperElements = swiper.target.querySelectorAll('.swiper-scrollbar, .swiper-pagination');
          swiperElements.forEach(($element) => {
            $element.style.display = 'none';
          });
        }
      });
    };

    var init = function () {
      window.addEventListener('resize', handler, false);
    };

    var addSlider = function (swiper) {
      if (swiper.target) {
        swipers.push(swiper);
        handler();
      }
    };

    Swiper.prototype.refresh = function () {
      this.update();
      this.scrollbar.updateSize();
    };

    DOMUtils.ready(init);

    return {
      addSlider: addSlider,
      handler: handler
    };
  });
